var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('FormDialogLayout',{scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('v-app-bar',{attrs:{"flat":""},scopedSlots:_vm._u([{key:"extension",fn:function(){return [_c('InputDateMonthRangePicker',{attrs:{"name":"inspection_month_picker","values":_vm.searchParams},on:{"onInput":_vm.onChangeSearchParams}}),_c('v-spacer'),_c('PageManager',{staticClass:"ml-5",attrs:{"values":_vm.searchParams,"page_counts_options":_vm.TABLES_PER_PAGE,"total_item":_vm.searchParams.totalItems,"pageCount":_vm.searchParams.pageCount},on:{"onInput":_vm.onChangeSearchParams}})]},proxy:true}])},[_c('v-toolbar-title',[_vm._v(_vm._s(_vm.title))]),_c('v-spacer'),_c('v-btn',{staticClass:"mr-5",attrs:{"color":"warning","disabled":!_vm.isShowAction},on:{"click":_vm.rejectConfirm}},[_vm._v(" "+_vm._s(_vm.DIALOG_BUTTON_NAMES.APPROVAL_UNLOCK)+" ")]),_c('v-btn',{staticClass:"mx-5",attrs:{"color":"primary","disabled":!_vm.isShowAction},on:{"click":_vm.approveConfirm}},[_vm._v(" "+_vm._s(_vm.DIALOG_BUTTON_NAMES.APPROVAL)+" ")]),_c('v-btn',{attrs:{"icon":""},on:{"click":_vm.closeForm}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1)]},proxy:true},{key:"main",fn:function(ref){
var params = ref.params;
return [_c('div',[_c('div',{staticClass:"note"},[_vm._v(_vm._s(_vm.CHECK_STATUS_MESSAGE.INSPECTION1))]),_c('v-data-table',{attrs:{"headers":_vm.LOG_TABLE_LABELS,"items":_vm.logList,"hide-default-footer":"","disable-sort":"","fixed-header":"","height":params.mainHeight - 50,"items-per-page":_vm.searchParams.pageCount,"show-select":"","noDataText":"データがありません。","outlined":""},scopedSlots:_vm._u([{key:"item.approved_status",fn:function(ref){
var item = ref.item;
return [_c('div',[_c('v-chip',{attrs:{"color":item.approval_status == 0 ? 'orange' : 'success'}},[_vm._v(_vm._s(item.approval_status == 0 ? "未承認" : "承認済")+" ")])],1)]}},{key:"item.result",fn:function(ref){
var item = ref.item;
return [_c('div',{staticStyle:{"font-size":"25px"}},[_vm._v(" "+_vm._s(_vm.handleResult(item.result))+" ")])]}},{key:"item.inspection_at",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(_vm.convertFormatDate(item))+" ")])]}}],null,true),model:{value:(_vm.selectedItems),callback:function ($$v) {_vm.selectedItems=$$v},expression:"selectedItems"}}),_c('Popup',{attrs:{"width":"500px","dialog":_vm.popups.isShowDialog}},[_c('ConfirmDialog',{attrs:{"isUnlock":true,"title":_vm.popups.titleDialog,"text":_vm.popups.messageDialog},on:{"close":_vm.closeDialog,"yes":_vm.actionDialog}})],1),_c('Popup',{attrs:{"width":"500px","dialog":_vm.popups.isShowApprovalDialog}},[_c('ConfirmDialog',{attrs:{"isApproval":true,"title":_vm.popups.titleDialog,"text":_vm.popups.messageDialog},on:{"close":_vm.closeDialog,"yes":_vm.actionDialog}})],1),_c('Popup',{attrs:{"width":"500px","dialog":_vm.popups.isShowConfirmDialog}},[_c('ConfirmDialog',{attrs:{"isClose":true,"isCenter":false,"title":"フォームを閉じる確認","text":"フォームを閉じますがよろしいですか？\n      入力内容は保存されません。\n\n      このページから移動してもよろしいですか？"},on:{"close":function($event){_vm.popups.isShowConfirmDialog = false},"yes":_vm.closeForm}})],1),_c('Popup',{attrs:{"width":"500px","dialog":_vm.popups.isShowUpdateErrorDialog}},[_c('ConfirmDialog',{attrs:{"hasDetails":false,"isTwoButton":false,"isCenter":false,"title":"承認不可","text":"自社が点検会社に設定されていない、点検実績も含まれています。"},on:{"close":function($event){_vm.popups.isShowUpdateErrorDialog = false}}})],1)],1)]}},{key:"footer",fn:function(){return [(_vm.searchParams.pageCount <= _vm.searchParams.totalItems)?_c('Pagination',{attrs:{"current":_vm.searchParams.currentPage,"total":_vm.searchParams.totalPage},on:{"pageUpdate":_vm.pageUpdate}}):_vm._e()]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }