<template>
  <FormDialogLayout
    :right="0"
    :width="30"
    className="company-form"
    :editable="isNewItem"
  >
    <template #header>
      <v-app-bar flat>
        <v-toolbar-title>
          {{ isNewItem ? formtitle + DIALOG_TITLE_ADDITIONS.ADD : formtitle }}
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn
          class="mr-5"
          v-if="!editable"
          color="primary"
          @click="onEditable"
        >
          {{ DIALOG_BUTTON_NAMES.EDIT }}
        </v-btn>
        <v-btn icon @click="onClickBtnClose">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <template v-slot:extension>
          <v-tabs v-model="tab" align-with-title>
            <v-tabs-slider color="primary"></v-tabs-slider>
            <v-tab v-for="(form, key) in FORMS" :key="key">
              {{ form.title }}
            </v-tab>
          </v-tabs>
        </template>
      </v-app-bar>
    </template>
    <template #main="{ params }">
      <ValidationObserver ref="observer" v-slot="observer">
        <v-tabs-items v-model="tab">
          <!-- 基本情報 -->
          <v-tab-item>
            <FormBasicInfo
              :editable="editable"
              :item="formValues"
              :isNewItem="isNewItem"
              :mainHeight="params.mainHeight"
              @formUpdate="formUpdate"
            />
          </v-tab-item>
        </v-tabs-items>
        <ValidationCallback :observer="observer" @callback="updateValidate" />
      </ValidationObserver>

      <Popup width="500px" :dialog="popups.isShowConfirmDialog">
        <ConfirmDialog
          @close="onClickBtnCancelForm"
          @yes="closeForm"
          :isClose="true"
          :isCenter="false"
          title="フォームを閉じる確認"
          text="フォームを閉じますがよろしいですか？
        入力内容は保存されません。

        このページから移動してもよろしいですか？"
        />
      </Popup>
    </template>
    <template #footer>
      <v-toolbar>
        <v-spacer></v-spacer>
        <v-btn
          v-if="editable"
          class="mr-5"
          outlined
          color="primary"
          @click="popups.isShowConfirmDialog = true"
        >
          {{ DIALOG_BUTTON_NAMES.CANCEL }}
        </v-btn>
        <v-btn
          v-if="editable"
          class="ml-5"
          :disabled="!valid"
          color="primary"
          :key="keySubmit"
          @click.once="onSubmit"
        >
          {{ DIALOG_BUTTON_NAMES.SAVE }}
        </v-btn>
      </v-toolbar>
    </template>
  </FormDialogLayout>
</template>

<script>
import { Store } from "@/store/Store.js";
import { ValidationObserver } from "vee-validate";
import ValidationCallback from "@/components/forms/elements/ValidationCallback.vue";
import { TITLE, FORMS, COMPANY_INITAL_ITEM } from "@/constants/COMPANY";
import {
  DIALOG_BUTTON_NAMES,
  DIALOG_TITLE_ADDITIONS,
} from "@/constants/COMMON";

// Components
import Popup from "@/components/common/Popup.vue";
import FormDialogLayout from "@/components/layout/FormDialogLayout";
import FormBasicInfo from "@/components/forms/company/FormBasicInfo.vue";
import ConfirmDialog from "@/components/dialog/ConfirmDialog";

//ストア
const STORE = "Company";

export default {
  data: () => {
    return {
      DIALOG_BUTTON_NAMES,
      DIALOG_TITLE_ADDITIONS,
      valid: false,
      tab: null,
      editable: false,
      popups: {
        isShowConfirmDialog: false,
      },
      isClickCloseBtn: false,
      TITLE,
      FORMS,
      formValues: { ...COMPANY_INITAL_ITEM },
      beforeUpdateItem: {},
      keySubmit: false,
    };
  },
  components: {
    ValidationObserver,
    ValidationCallback,
    Popup,
    FormDialogLayout,
    ConfirmDialog,
    FormBasicInfo,
  },
  props: {
    isNewItem: Boolean,
  },
  mounted() {
    /**
     * (Common)
     * Init Screen
     */
    this.$watch(
      () => this.isNewItem,
      (flag) => {
        if (flag) this.editable = true;
      },
      {
        immediate: true,
        deep: true,
      }
    );

    /**
     * mode: detail
     * get Company detail
     */
    this.$watch(
      () => [Store.getters[`${STORE}/getCompanyDetail`], this.editable],
      (data) => {
        if (!this.isNewItem) {
          this.formValues = { ...data[0] };
          this.beforeUpdateItem = { ...data[0] };
        }
      },
      {
        immediate: true,
        deep: true,
      }
    );
  },
  computed: {
    formtitle() {
      return this.isNewItem ? this.TITLE.NEW : this.TITLE.EDIT;
    },
  },
  methods: {
    formUpdate(params) {
      this.formValues = { ...this.formValues, ...params };
    },

    onEditable() {
      this.editable = true;
    },

    onDetail() {
      this.editable = false;
    },

    /**
     * onSubmit
     */
    async onSubmit() {
      if (
        (this.isNewItem || this.beforeUpdateItem.corporate_number === null) &&
        this.formValues.is_required_corporate_number
      ) {
        let checkResult = await this.checkSubmit();
        if (!checkResult) {
          return;
        }
      }
      await this.submit();
    },
    async checkSubmit() {
      const apiParams = { ...this.formValues };
      const result = await Store.dispatch(
        `${STORE}/checkCompanyExits`,
        apiParams.corporate_number
      );
      if (result.hasError) {
        const { data } = result.response;
        Store.dispatch("Error/show", {
          status: data.status_code,
          message: data.message,
        });
        return false;
      }
      const entries = result.data.contents.entries;
      if (entries.isExits) {
        this.keySubmit = !this.keySubmit;
        Store.dispatch("Error/show", {
          status: 200,
          message: "既に登録済みの会社です。",
        });
        return false;
      }
      return true;
    },
    async submit() {
      const apiParams = { ...this.formValues };
      const hasId = "id" in this.formValues;
      const result = await Store.dispatch(
        hasId ? `${STORE}/update` : `${STORE}/post`,
        apiParams
      );
      if (result.hasError) {
        this.keySubmit = !this.keySubmit;
        return;
      } else {
        if (this.isNewItem) {
          this.$emit("updateCompany", result.data.contents.entries.id);
          this.$emit("cancel");
        } else {
          this.getItems(apiParams.id);
          this.onDetail();
        }
        Store.dispatch("Toast/show", {
          status: 200,
          message: hasId ? "更新しました" : "登録しました",
        });
      }
    },
    updateValidate({ valid }) {
      this.valid = valid;
    },

    /**
     * handle close popup confirm
     */
    closeForm() {
      if (this.editable && !this.isNewItem && !this.isClickCloseBtn) {
        this.popups.isShowConfirmDialog = false;
        this.formValues = { ...this.beforeUpdateItem };
        this.editable = false;
      } else {
        this.$emit("cancel");
      }
    },
    /**
     * on click button close
     */
    onClickBtnClose() {
      if (!this.editable && !this.isNewItem) {
        this.$emit("cancel");
      } else {
        this.isClickCloseBtn = true;
        this.popups.isShowConfirmDialog = true;
      }
    },
    /**
     * get data company detail
     */
    async getItems(id) {
      Store.dispatch(`${STORE}/getCompanyDetail`, id);
    },
    onClickBtnCancelForm() {
      this.popups.isShowConfirmDialog = false;
      this.isClickCloseBtn = false;
    },
  },
};
</script>
