<template>
  <FormDialogLayout>
    <template #header>
      <v-app-bar flat>
        <v-toolbar-title>
          {{ isNewItem ? formtitle + DIALOG_TITLE_ADDITIONS.ADD : formtitle }}
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn v-if="!editable" color="primary" @click="onEditable">
          編集
        </v-btn>
        <v-btn icon @click="onClickBtnClose">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <template v-slot:extension>
          <v-tabs v-model="tab" align-with-title>
            <v-tabs-slider color="primary"></v-tabs-slider>
            <v-tab v-for="(form, key) in MACHINE_SITES_FORMS" :key="key">
              {{ form.title }}
            </v-tab>
          </v-tabs>
        </template>
      </v-app-bar>
    </template>
    <template #main="{ params }">
      <ValidationObserver ref="observer" v-slot="observer">
        <v-tabs-items v-model="tab">
          <v-tab-item>
            <MachineSitesFormPage1
              :editable="editable"
              :item="item"
              :isNewItem="isNewItem"
              @formUpdate="formUpdate"
              @hiddenButton="hiddenButton"
              @showButton="showButton"
            />
          </v-tab-item>
          <v-tab-item>
            <MachineSitesFormPage2
              :editable="editable"
              :item="item"
              :mainHeight="params.mainHeight"
              @formUpdate="formUpdate"
              @deleteMachine="deleteMachine"
            />
          </v-tab-item>
          <v-tab-item>
            <MachineSitesFormPage3
              :editable="editable"
              :item="item"
              :mainHeight="params.mainHeight"
              @formUpdate="formUpdate"
              @deleteMachine="deleteMachine"
            />
          </v-tab-item>
        </v-tabs-items>
        <ValidationCallback :observer="observer" @callback="updateValidate" />
      </ValidationObserver>

      <Popup width="500px" :dialog="popups.isShowConfirmDialog">
        <ConfirmDialog
          @close="onClickBtnCancelForm"
          @yes="closeForm"
          :isClose="true"
          :isCenter="false"
          title="フォームを閉じる確認"
          text="フォームを閉じますがよろしいですか？
        入力内容は保存されません。

        このページから移動してもよろしいですか？"
        />
      </Popup>
    </template>
    <template #footer>
      <v-toolbar>
        <v-spacer></v-spacer>
        <v-btn
          v-if="editable"
          class="mr-5"
          outlined
          color="success"
          @click="popups.isShowConfirmDialog = true"
        >
          {{ DIALOG_BUTTON_NAMES.CANCEL }}
        </v-btn>
        <v-btn
          v-if="editable && isNewItem && isHiddenButton"
          class="mx-5"
          :disabled="!valid"
          color="primary"
          @hiddenButton="hiddenButton"
          @showButton="showButton"
          @click="onSubmit(false)"
        >
          {{ DIALOG_BUTTON_NAMES.CONTINUE }}
        </v-btn>
        <v-btn
          v-if="editable"
          class="ml-5"
          :disabled="!valid"
          color="primary"
          :key="keyButton"
          @click.once="onSubmit(true)"
        >
          {{ DIALOG_BUTTON_NAMES.SAVE }}
        </v-btn></v-toolbar
      >
    </template>
  </FormDialogLayout>
</template>
<script>
import { Store } from "@/store/Store.js";
import _ from "lodash";
import { ValidationObserver } from "vee-validate";
import {
  DIALOG_BUTTON_NAMES,
  DIALOG_TITLE_ADDITIONS,
} from "@/constants/COMMON";
import {
  MACHINE_SITES_TITLE_FORM,
  MACHINE_SITES_FORMS,
} from "@/constants/MACHINE_SITES";

// Components
import ValidationCallback from "@/components/forms/elements/ValidationCallback.vue";
import Popup from "@/components/common/Popup.vue";
import FormDialogLayout from "@/components/layout/FormDialogLayout";
import ConfirmDialog from "@/components/dialog/ConfirmDialog";
import MachineSitesFormPage1 from "@/components/forms/fields/fieldMachines/MachineSitesFormPage1.vue";
import MachineSitesFormPage2 from "@/components/forms/fields/fieldMachines/MachineSitesFormPage2.vue";
import MachineSitesFormPage3 from "@/components/forms/fields/fieldMachines/MachineSitesFormPage3.vue";

const STORE = "MachineSites";

export default {
  data: () => {
    return {
      DIALOG_BUTTON_NAMES,
      DIALOG_TITLE_ADDITIONS,
      MACHINE_SITES_TITLE_FORM,
      MACHINE_SITES_FORMS,
      valid: false,
      tab: null,
      editable: false,
      popups: {
        isShowConfirmDialog: false,
      },
      isClickCloseBtn: false,
      beforeUpdateItem: {},
      isHiddenButton: true,
    };
  },
  components: {
    ValidationObserver,
    ValidationCallback,
    Popup,
    FormDialogLayout,
    ConfirmDialog,
    MachineSitesFormPage1,
    MachineSitesFormPage2,
    MachineSitesFormPage3,
  },
  props: {
    item: Object,
    isNewItem: Boolean,
    keyButton: Boolean,
  },
  mounted() {
    this.$watch(
      () => this.isNewItem,
      (flag) => {
        if (flag) this.editable = true;
      },
      {
        immediate: true,
        deep: true,
      }
    );
    this.$watch(
      () => [Store.getters[`${STORE}/getMachineSitesDetail`], this.editable],
      (data) => {
        if (!this.isNewItem) {
          this.beforeUpdateItem = _.cloneDeep(data[0]);
        }
      },
      {
        immediate: true,
        deep: true,
      }
    );
  },
  computed: {
    formtitle() {
      return this.isNewItem
        ? MACHINE_SITES_TITLE_FORM.NEW
        : MACHINE_SITES_TITLE_FORM.EDIT;
    },
  },
  methods: {
    hiddenButton() {
      this.isHiddenButton = false;
    },
    showButton() {
      this.isHiddenButton = true;
    },
    formUpdate(params) {
      let _params = _.cloneDeep(params);
      let beforeUpdateItem = _.cloneDeep(this.beforeUpdateItem);
      if (_params.machine_id == beforeUpdateItem.machine_id) {
        _params.machine_field_inspections.forEach((element, index) => {
          if (element.id && element.modified_flg != 1) {
            element.id = beforeUpdateItem.machine_field_inspections[index].id;
          }
        });
      }
      this.$emit("formUpdate", _params);
    },

    onEditable() {
      this.editable = true;
    },

    onSubmit(flag) {
      this.$emit("submit", flag);
    },

    updateValidate({ valid }) {
      this.valid = valid;
    },

    closeForm() {
      if (this.editable && !this.isNewItem && !this.isClickCloseBtn) {
        this.popups.isShowConfirmDialog = false;
        const item = _.cloneDeep(this.beforeUpdateItem);
        this.formUpdate(item);
        this.editable = false;
      } else {
        this.$emit("cancel");
      }
    },
    /**
     *
     * click button Close (X)
     */
    onClickBtnClose() {
      if (!this.editable && !this.isNewItem) {
        this.$emit("cancel");
      } else {
        this.isClickCloseBtn = true;
        this.popups.isShowConfirmDialog = true;
      }
    },
    onClickBtnCancelForm() {
      this.popups.isShowConfirmDialog = false;
      this.isClickCloseBtn = false;
    },
    deleteMachine(array_id) {
      this.$emit("deleteIds", array_id);
    },
  },
};
</script>
